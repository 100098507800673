import React from 'react'
import './mentions-legales.scss'
import { StaticImage } from 'gatsby-plugin-image'
import LayoutMl from '../components/layout-ml'

export default function Accueil() {

    return (
        <div className="mentions_legales">
            <LayoutMl>

                <div className="header">
                    <StaticImage
                        src="../images/chalet/chambre_7_chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant.jpg"
                        alt="chambre_7_chalet_standing_montagne_les_saisies_alpes_savoie_espace_diamant"
                        placeholder="blurred"
                        className="background"
                    />
                    <h1>Mentions Légales</h1>
                </div>
                <div className="page_mentions_legales">
                    <section className="bloc_mentions_legales container">
                        <div className="text_mentions_legales">
                            <div className="separator_margin">
                                <h3>Responsable de publication</h3>
                                <p><span className="bold">Responsable :</span> Yann Bouchage</p>
                                <p><span className="bold">Nom commercial :</span> Chalet Vers les Plans</p>
                                <p><span className="bold">Statut juridique :</span> Indivision</p>
                                <p><span className="bold">Adresse : </span> 549 rue de Peguet - 73800 - Ste Hélène du lac</p>
                                <p><span className="bold">N° SIRET :</span> 80777397300019</p>
                                <p><span className="bold">Code APE :</span> 5520Z - Hébergement touristique</p>
                            </div>
                            <div className="separator_margin">
                                <h3>Domaine et Hébergement</h3>
                                <p><span className="bold">OVH SAS</span>, siège social au 2 rue Kellermann - 59100 Roubaix - France</p>
                                <p><span className="bold">Hébergement :</span> Netlify, siège social au <i>2325 3rd Street, Suite 215, San Francisco, California 94107</i></p>
                            </div>
                            <div className="separator_margin">
                                <h3>Condition Générales d'Utilisation</h3>
                                <p className="bold">Cookies</p>
                                <p>Les informations obtenues par le biais du formulaire de contact, des cookies et de l’outil d’analyse de trafic sont destinées au seul usage de Yann Bouchage. Ces informations sont réservées à un usage d’analyse et ne pourront être vendues ou mises à la disposition de tierces parties.</p>
                                <p>Les données enregistrés par l'utilisateur lors de l'inscription à la newsletter seront gardées jusqu'à ce que l'utilisateur se désabonne via le lien de désabonnement situé en bas de la newsletter.</p>
                                <p>Yann Bouchage se réserve le droit de modifier, à tout moment et sans préavis, les présentes CGU afin de les adapter aux évolutions du Site et/ou de son exploitation. Un email sera envoyé pour prévenir les utilisateurs enregistrés des nouvelles CGU.</p>
                                <p className="bold">Déclaration de la CNIL</p>
                                <p>En application de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez des droits d’opposition (art. 26), d’accès (art. 34 à 38) et de rectification (art. 36) des données vous concernant. Pour l’exercer, vous pouvez faire votre demande à Yann Bouchage, responsable de publication du site www.menuierie-beaufortain.fr via le formulaire de contact de ce site.</p>
                            </div>

                            <h3>Conditions Générales de location</h3>
                            <h4>Article 1 - le Tarif</h4>
                            <p>Comprend l’eau, l’électricité, le chauffage, le linge de lit et le linge de toilette.</p>
                            <h4>Article 2 - durée du séjour</h4>
                            <p>Le locataire signataire du présent contrat conclu pour une durée déterminée ne pourra en aucune circonstance se prévaloir d’un quelconque droit au maintien dans les lieux à l’issue du séjour.</p>
                            <h4>Article 3 – conclusion du contrat</h4>
                            <p>
                                La réservation devient effective dès lors que le locataire aura fait parvenir au propriétaire un acompte de 30 % du montant total de la location et un exemplaire du contrat signé avant la date indiquée au recto. Un deuxième exemplaire est à conserver par le locataire. La location conclue entre les parties au présent acte ne peut en aucun cas bénéficier même partiellement à des tiers, personnes physiques ou morales, sauf accord écrit du propriétaire<br />
                                Toute infraction à ce dernier alinéa serait susceptible d’entraîner la résiliation immédiate de la location aux torts du locataire, le produit de la location restant définitivement acquis au propriétaire.
                            </p>
                            <h4>Article 4 – absence de rétractation</h4>
                            <p>Quel que soit le mode de réservation, le locataire ne bénéficie pas du délai de rétractation, et ce, conformément à l‘article L121-20-4 du code de la consommation relatif notamment aux prestations de services d’hébergement fournies à une date ou selon une périodicité déterminée.</p>
                            <h4>Article 5 – annulation par le locataire</h4>
                            <p>
                                Toute annulation doit être notifiée par lettre recommandée avec AR à l’adresse du propriétaire. Pour toute réservation le solde reste dû au propriétaire.<br />
                                <h5>a) Annulation avant l’arrivée dans les lieux</h5>
                                <p>
                                    L’acompte reste acquis au propriétaire. Celui-ci pourra demander le solde du montant du séjour, si l’annulation intervient moins de 30 jours avant la date prévue d’entrée dans les lieux.<br />
                                    Si le locataire ne se manifeste pas dans les 24 heures qui suivent la date d’arrivée indiquée sur le contrat, le présent contrat devient nul et le propriétaire peut disposer de son gîte. L’acompte reste également acquis au propriétaire qui demandera le solde de la location.
                                </p>
                                <h5>b) Si le séjour est écourté</h5>
                                <p>Le prix de la location reste acquis au propriétaire. Il ne sera procédé à aucun remboursement.</p>
                            </p>
                            <h4>Article 6 – annulation par le propriétaire</h4>
                            <p>Toute annulation doit être notifiée par lettre recommandée avec AR au locataire. Le propriétaire reverse au locataire l’intégralité des sommes versées, ainsi qu’une indemnité au moins égale à celle que le locataire aurait supportée si l’annulation était intervenue de son fait à cette date.</p>
                            <h4>Article 7 - arrivée & sortie</h4>
                            <h5>a) Arrivée</h5>
                            <p>Le locataire doit se présenter le jour précisé et l’heure mentionnée sur le présent contrat. En cas d’arrivée tardive ou différée, le locataire doit prévenir le propriétaire.</p>
                            <h5>b) Sortie</h5>
                            <p>Le locataire s’engage, à son départ, à rendre les locaux propres, le mobilier, les installations, l’équipement intérieur et extérieur en bon état de fonctionnement et d’une manière générale dans l’état qui lui a été fournis. Toute perte ou dégats occasionnés par le locataire fera l’objet d’une indemnisation.</p>
                            <h4>Article 8 – règlement du solde</h4>
                            <p>Le solde de la location est versé à l’entrée dans les lieux ainsi que la taxe de séjour.</p>
                            <h4>Article 9 – état des lieux</h4>
                            <p>
                                Un inventaire est établi en commun et signé par le locataire et le propriétaire ou son représentant à l’arrivée et au départ du gîte. Cet inventaire constitue la seule référence en cas de litige concernant l’état des lieux.<br />
                                L’état de propreté du gîte à l’arrivée du locataire devra être constaté dans l’état des lieux. Le nettoyage des locaux est à la charge du locataire pendant la période de location et avant son départ. Le propriétaire peut toutefois proposer un forfait ménage sur la base tarifaire et condition indiquée au recto.
                            </p>
                            <h4>Article 10 – dépôt de garantie ou caution</h4>
                            <p>
                                À l’arrivée du locataire, un dépôt de garantie ou caution dont le montant est indiqué au recto du présent contrat est demandé par le propriétaire, après réalisation obligatoire d’un état des lieux. Après l’établissement contradictoire de l’état des lieux de sortie, ce dépôt est restitué, déduction faite du coût de remise en état des lieux si des dégradations étaient constatées.<br />
                                Un forfait ménage est proposé au locataire sur demande. Après validation par le propriétaire le chèque d’acompte sera restitué le jour du départ.<br />
                                En cas de départ anticipé (antérieur à l’heure mentionnée sur le présent contrat) empêchant l’établissement de l’état des lieux le jour même du départ du locataire, le dépôt de garantie ou caution est renvoyé par le propriétaire dans un délai n’excédant pas une semaine.
                            </p>
                            <h4>Article 11 – utilisation des lieux</h4>
                            <p>Le locataire devra assurer le caractère paisible de la location et en faire usage conformément à la destination des lieux.</p>
                            <h4>Article 12 – capacité</h4>
                            <p>Le présent contrat est établi pour une capacité maximum de 15 personnes. Si le nombre de locataires dépasse la capacité d’accueil édictée sur le contrat, le propriétaire peut refuser les personnes supplémentaires. Toute modification ou rupture du contrat sera considérée à l’initiative du client.</p>
                            <h4>Article 13 – accueil des animaux</h4>
                            <p>Les animaux quels qu’ils soient ne sont pas admis dans le logement. Le contrat pourra être résilié aux torts du client en cas de non-respect de ces conditions de séjour.</p>
                            <h4>Article 14 – assurances</h4>
                            <p>Le locataire est responsable de tous les dommages survenant de son fait. Il est tenu d’être assuré par un contrat d’assurance type villégiature pour ces différents risques. Le locataire doit informer immédiatement le propriétaire de tout sinistre et des dégradations se produisant dans le chalet, même s’il en résulte aucun dommage apparent.</p>
                            <h4>Article 15 – prestations</h4>
                            <p>Des prestations peuvent être réalisées sur demande, le tarif sera transmis au moment de la réservation de ces prestations.</p>
                            <h4>Article 16 – litiges</h4>
                            <p>Toute réclamation relative à la qualité des lieux et à la conformité du descriptif, doit être soumise au propriétaire dans les 1 jours à compter de l’entrée dans les lieux. Toute autre réclamation doit lui être adressée dans les meilleurs délais par lettre recommandé.</p>

                            <h3>Conditions d’annulation spéciales COVID-19</h3>
                            <p>
                                Remboursement des sommes versées, dans les cas ci-dessous :
                                <li>Si de nouvelles mesures de confinement national interviennent lors du séjour (remboursement au prorata temporis)</li>
                                <li>Si le client est dans l’impossibilité de se rendre sur son lieu de vacance à cause de nouvelles restrictions de circulation et fermeture des frontières nationales de départ et/ou de destination.</li>
                                <li>En cas de confinement et non ouverture du domaine skiable liés au Covid 19 sur décision gouvernementale vous empêchant d’effectuer votre séjour.</li>
                            </p>

                            <h3>Mesures sanitaires spéciales COVID-19</h3>
                            <p>
                                <li>Désinfection du trousseau de clés entre chaque location</li>
                                <li>Plan de désinfection spécifique en respect des normes sanitaires avec des produits spécifiques</li>
                                <li>Linge passé en blanchisserie et changé entre chaque locataire</li>
                                <li>Masque obligatoire dans la structure d’accueil</li>
                                <li>Mise à disposition de gel hydroalcoolique à l’entrée</li>
                            </p>
                        </div>
                    </section>
                </div>
            </LayoutMl>
        </div>
    )
}